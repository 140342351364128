<template>
  <div class="box box--md box--white mt-16">
    <table class="table table--list three-items">
      <tbody>
        <tr v-if="showUser">
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Email</p>
              <h2 class="headline--md mt-4">{{ transaction.user.email }}</h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Full Name</p>
              <h2 class="headline--md mt-4">{{ transaction.user.fullName }}</h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Phone</p>
              <h2 class="headline--md mt-4">{{ transaction.user.countryCode }} {{ transaction.user.phone }}</h2>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Transaction hash</p>
              <div class="headline--md mt-4 clipboard-copy">
                <p class="clipboard-copy__text">
                  <span class="clipboard-copy__text-inner">{{ transaction.tnxHash }}</span>
                  <copy-clipboard @on-copy="doCopy($event)" :external-link="scanTrackerTnxLink" />
                </p>
              </div>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Status</p>
              <h2
                class="headline--md mt-4 text-uppercase text-bold"
                :class="transaction.status | format_transaction_status_class"
              >
                {{ transaction.status | format_transaction_status_text }}
              </h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Transaction time</p>
              <h2 class="headline--md mt-4">{{ transaction.add_time | moment_lts }}</h2>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">From</p>
              <div class="clipboard-copy">
                <h2 class="headline--md mt-4 clipboard-copy__text">
                  <span class="clipboard-copy__text-inner">{{ transaction.from }}</span>
                  <copy-clipboard @on-copy="doCopy($event)" :external-link="scanTrackerAddressLink(transaction.from)" />
                </h2>
              </div>
            </div>
          </td>
          <td>
            <div class="statistic">
              <div class="statistic">
                <p class="statistic__title text-warm">To</p>
                <div class="clipboard-copy">
                  <h2 class="headline--md mt-4 clipboard-copy__text">
                    <span class="clipboard-copy__text-inner">{{ transaction.to }}</span>
                    <copy-clipboard @on-copy="doCopy($event)" :external-link="scanTrackerAddressLink(transaction.to)" />
                  </h2>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Value</p>
              <h2 class="headline--md mt-4">
                {{ transaction.amountShort | numeric_float_number_with_commas }} {{ transaction.asset_symbol }}
              </h2>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Transaction Fee</p>
              <h2 class="headline--md mt-4">{{ transaction.feeShort }} {{ feeSymbol }}</h2>
            </div>
          </td>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Wallet type</p>
              <h2 class="headline--md mt-4">{{ transaction.assetSymbol | format_transaction_wallet_type }}</h2>
            </div>
          </td>
          <td v-if="showTnxType">
            <div class="statistic">
              <p class="statistic__title text-warm">Transaction Type</p>
              <span :class="`tag tag--md ${transactionType() === 'deposit' ? 'tag--warning' : 'tag--success'}`">
                {{ transactionType() }}
              </span>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="statistic">
              <p class="statistic__title text-warm">Network Type</p>
              <div class="mt-4">
                <toko-eth-icon v-if="transaction.networkType === 1" />
                <toko-bsc-icon v-if="transaction.networkType === 2" />
                <toko-btc-icon v-if="transaction.networkType === 3" />
              </div>
            </div>
          </td>
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CopyClipboard from '@/components/CopyClipboard'
import TokoBscIcon from '@/ui/toko-icons/TokoBSC'
import TokoEthIcon from '@/ui/toko-icons/TokoETH'
import TokoBtcIcon from '@/ui/toko-icons/TokoBTC'
import copyClipboard from '@/mixins/copy-clipboard'
import { TRANSACTION_REQUEST_TYPE, TNX_NETWORK_TYPES } from '@/utils/key-mapping'

export default {
  name: 'Transaction',

  props: {
    transaction: {
      type: Object,
      require: true
    },

    showUser: {
      type: Boolean,
      default: true
    },

    network: {
      type: Number,
      default: 1
    }
  },

  mixins: [copyClipboard],

  computed: {
    showTnxType() {
      return (
        this.transaction &&
        this.transaction.optionsData &&
        !!(this.transaction.optionsData.staking || this.transaction.optionsData.crowdfunding_order)
      )
    },

    scanTrackerTnxLink() {
      let scanLink
      if (this.transaction.networkType === TNX_NETWORK_TYPES.btc) {
        scanLink = `/blockcypher/${this.transaction.tnxHash}`
      }

      if (this.transaction.networkType === TNX_NETWORK_TYPES.ethereum) {
        scanLink = `/etherscan/${this.transaction.tnxHash}`
      }

      if (this.transaction.networkType === TNX_NETWORK_TYPES.bsc) {
        scanLink = `/bsc/${this.transaction.tnxHash}`
      }

      return scanLink
    },

    feeSymbol() {
      return this.transaction.networkType === TNX_NETWORK_TYPES.ethereum ? 'ETH' : 'BNB'
    }
  },

  components: {
    CopyClipboard,
    TokoBscIcon,
    TokoEthIcon,
    TokoBtcIcon
  },

  methods: {
    transactionType() {
      if (!this.transaction || !this.showTnxType) return

      if (this.transaction.optionsData) {
        if (this.transaction.optionsData.staking) {
          if (this.transaction.optionsData.staking.type === TRANSACTION_REQUEST_TYPE.deposit) {
            return 'deposit'
          }

          if (this.transaction.optionsData.staking.type === TRANSACTION_REQUEST_TYPE.withdraw) {
            return 'withdraw'
          }
        }

        if (this.transaction.optionsData.crowdfunding_order) {
          if (this.transaction.optionsData.crowdfunding_order.type === TRANSACTION_REQUEST_TYPE.deposit) {
            return 'deposit'
          }

          if (this.transaction.optionsData.crowdfunding_order.type === TRANSACTION_REQUEST_TYPE.withdraw) {
            return 'withdraw'
          }
        }
      }
    },

    scanTrackerAddressLink(addr) {
      let scanLink
      if (this.transaction.networkType === TNX_NETWORK_TYPES.btc) {
        scanLink = `/blockcypher/${addr}`
      }

      if (this.transaction.networkType === TNX_NETWORK_TYPES.ethereum) {
        scanLink = `/etherscan-address/${addr}`
      }

      if (this.transaction.networkType === TNX_NETWORK_TYPES.bsc) {
        scanLink = `/bsc-address/${addr}`
      }

      return scanLink
    }
  }
}
</script>
